// 引入工具函数
import { http, httpimg } from '../../api/index'

// 判断是否为 需要申请的
export const configKey = params => http.get(`/ohealth/api/v1/system/sysconfig/configKey/${params}` )

// 流调组管理-列表
export const getSurveyGroupList = params => http.get('/ohealth/api/v1/epidemicControl/surveygroupinfo/getSurveyGroupList', params)

export const getSurveyGroupInfo = params => http.get('/ohealth/api/v1/epidemicControl/surveygroupinfo/getSurveyGroupInfo', params)

export const saveSurveyGroup = params => http.post('/ohealth/api/v1/epidemicControl/surveygroupinfo/saveSurveyGroup', params)

export const updateSurveyGroup = params => http.put('/ohealth/api/v1/epidemicControl/surveygroupinfo/updateSurveyGroup', params)

export const updateSurveyGroupStatus = params => http.put('/ohealth/api/v1/epidemicControl/surveygroupinfo/updateSurveyGroupStatus', params) 